import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout/Layout";
import Seo from "../components/seo";
import { TextContent } from "../styles/StyledComponents";

const PrivacyPolicy = () => {
  const { contentfulPrivacyPolicy: privacyPolicy } = useStaticQuery(
    graphql`
      query PrivacyPolicyQuery {
        contentfulPrivacyPolicy {
          content {
            raw
          }
        }
      }
    `
  );

  const parsedPolicy = JSON.parse(privacyPolicy.content.raw);

  const Policy = documentToReactComponents(parsedPolicy);

  const PrivacyPolicyContent = styled.div`
    padding: 0px 80px;
    margin: 20px 0px;
  `;

  return (
    <Layout vertical={true}>
      <Seo title="Privacy Policy" description="MDT privacy policy" />
      <PrivacyPolicyContent>{Policy}</PrivacyPolicyContent>
    </Layout>
  );
};

export default PrivacyPolicy;
